.candidate-hotlist-table-main-div {
  height: auto;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  /* border: 1px solid black; */
  margin-top: 20px;
  margin-left: 45px;
  margin-right: 45px;
}

.candidate-hotlist-table-main-div .table-box {
  width: 100%;
  height: 73vh;
  /* overflow-x: scroll; */
  overflow: scroll;
}

.candidate-hotlist-table-main-div table {
  border-collapse: collapse;
  position: relative;
  width: 100%;
}
/* .candidate-hotlist-table-main-div .candidate-hotlist-table-input-and-back-but {
 
} */
/* .candidate-hotlist-table-main-div .candidate-hotlist-table-thead-data tr {
  display: flex;
    flex-direction: row;
} */
.candidate-hotlist-table-main-div .candidate-hotlist-table-thead-data th {
  text-align: start !important;
  /* border-top: 1px solid black;
    border-bottom: 1px solid black; */
  height: 60px;
  white-space: nowrap;
  padding: 10px 15px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  text-align: center;
  border-bottom: 1px solid #cfcfcf;
  font-size: 15px;
  /* opacity: 0.36; */
}

.candidate-hotlist-table-main-div .candidate-hotlist-table-thead-data {
  position: sticky;
  top: 0;
}

.candidate-hotlist-damin-and-nameSearch .ant-select-selector {
  width: 100%;
  padding-left: 13px;
}
.candidate-hotlist-damin-and-nameSearch .ant-select-arrow {
  display: none !important;
}
.candidate-hotlist-damin-and-nameSearch .ant-select-selection-item {
  background: #dfe7f0 !important;
  font-weight: bold;
}
.candidate-hotlist-damin-and-nameSearch .anticon-close {
  position: relative;
  bottom: 9px;
  left: 0px;
  font-size: 7px;
  color: black;
}

.candidate-hotlist-damin-and-nameSearch .ant-select-selection-item-remove {
  height: 8px !important;
}

.lableEnable {
  cursor: pointer;
}
.lableDisable {
  cursor: not-allowed;
}

.candidate-hotlist-table-main-div .resetFilterIcon {
  cursor: pointer;
  width: 33px;
  height: 33px;
}
.resetFilterIcon:hover g > path {
  fill: rgb(38, 95, 157);
}
.resetFilterIcon:hover g > text {
  fill: rgb(38, 95, 157);
}

.candidate-header-center .ant-select-selector {
  width: 44rem;
  padding-left: 10px;
}

.candidate-header-center .ant-select-arrow {
  display: none !important;
}

.candidate-header-center .ant-select-selector {
  border: none !important;
}

.candidate-hotlist-table-main-div .candidate-hotlist-table-input-and-back-but {
  background: #e6e6e6 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

/* .candidate-hotlist-table-main-div .ant-select-selector{
  border:none !important
} */

.candidate-hotlist-table-main-div .ant-select-selection-overflow-item-suffix {
  opacity: 0 !important;
}

.candidate-hotlist-table-input-and-back-but .addhotlist-link {
  list-style: none;
}

.candidate-hotlist-table-main-div .candidate-hotlist-table-tbody-data td {
  text-align: start !important;
  /* border-top: 1px solid black;
    border-bottom: 1px solid black; */
  height: 47.6px !important;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px 15px;
  border-bottom: 1px solid #cfcfcf;
  border-top: 1px solid #cfcfcf;
  font-size: 14px;
  font-weight: 600;
  /* opacity: 0.36; */
}

.candidate-hotlist-table-main-div .candidate-hotlist-table-input {
  width: 290px;
  border-radius: 15px;
  height: 32px;
  outline: none;
  /* border: none; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000029;
  border: 1px solid #d5e9ff;
  opacity: 1;
  padding-left: 30px;
}
.candidate-hotlist-table-main-div .candidate-hotlist-table-add-hotlist-but {
  width: 110px;
  height: 38px;
  color: #ffffff;
  border: none;
  background: #265f9d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
  font-weight: 500;
}
.candidate-hotlist-table-main-div .candidate-hotlist-table-delet-but {
  width: 75px;
  height: 38px;
  text-align: center;
  /* display: flex; */
  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 3px;
  border: none;
  background: #d4d4d4 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  color: #a4a5a5;
}
.candidate-hotlist-table-main-div .candidate-hotlist-table-in-reset-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}
.candidate-hotlist-table-main-div .candidate-hotlist-table-in-reset-export {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}

.candidate-hotlist-table-in-reset-export .exportIcon {
  width: 38px;
  height: 38px;
  cursor: pointer;
}

.candidate-hotlist-table-in-reset-export .exportIcon:hover > path {
  fill: #265f9d;
}
.candidate-hotlist-table-in-reset-export .exportIcon:hover > text {
  fill: #265f9d;
}

.candidate-hotlist-table-main-div .candidate-hotlist-table-delet-but-hover {
  width: 75px;
  height: 38px;
  text-align: center;
  /* display: flex; */
  align-items: center;
  display: flex;
  font-size: 12px;
  gap: 3px;
  border: none;
  border-radius: 5px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #aa0000;
  color: #ffffff;
}

.notesTd span {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: start;
  text-overflow: ellipsis;
}

/* .candidate-hotlist-table-main-div .candidate-hotlist-table-delet-but:hover {
  background-color: #aa0000;
  color: #ffffff;
} */
.candidate-hotlist-table-main-div .candidate-hotlist-table-in-button-section {
  display: flex;
  align-items: center;
  gap: 12px;
}
.candidate-hotlist-table-main-div .search-icon-in-candidate-hotlist {
  position: relative;
  left: 1.8rem;
  top: 0.2rem;
}
.candidate-hotlist-table-main-div input[type="checkbox"] {
  accent-color: green;
  height: 1rem;
  width: 1rem;
}
.candidate-hotlist-table-main-div .data-not-found {
  /* display: flex;
  justify-content: center;
  font-size: 20px;
  position: relative;
  left: 30rem;
  align-items: center;
  width: 500px; */
  display: flex;
  position: absolute;
  left: 35rem;
  bottom: -14rem;
  font-size: 30px;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-weight: 600;
}
.candidate-hotlist-table-main-div .data-not-found-icon {
  font-size: 50px;
}

/* pagination */

.candidateHotlistAntdPaginationMainDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e6;
  height: 50px !important;
  padding-left: 35px;
  padding-right: 35px;
  width: 100%;
}

.candidateHotlistAntdPaginationMainDiv
  .candidatesPerPageDiv
  .ant-select-selector {
  padding: 0 !important;
  margin-left: 15px;
  background-color: #a4a5a5 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}
.candidateHotlistAntdPaginationMainDiv
  .candidatesPerPageDiv
  .ant-select-selection-item {
  padding-inline-end: 0px !important;
  /* padding-left: 11px !important; */
  text-align: center !important;
}
.candidateHotlistAntdPaginationMainDiv .showingPageination {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  width: 40%;
  /* width: 100%; */
  /* margin-left: 30px; */
}

.candidateHotlistAntdPaginationMainDiv .nex-prev {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid lightgray;
  cursor: pointer;
}

.candidateHotlistAntdPaginationMainDiv .nex-prev-disable {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid lightgray;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.candidateHotlistAntdPaginationMainDiv .candidatesPerPageDiv {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  width: 20%;
  /* width: 100%; */
}

.candidateHotlistAntdPaginationMainDiv .paginationMainDiv {
  display: flex;
  justify-content: end;
  width: 40%;
}

.candidateHotlistAntdPaginationMainDiv .ant-pagination-options {
  display: none !important;
}

.candidateHotlistAntdPaginationMainDiv .ant-select-arrow {
  display: none !important;
}

.candidateHotlistAntdPaginationMainDiv .ant-pagination-item-active {
  color: black !important;
  background-color: white;
  border-radius: 0px;
}

.candidateHotlistAntdPaginationMainDiv .ant-pagination-item-active a {
  background-color: black;
  color: white;
}

.candidateHotlistAntdPaginationMainDiv .ant-pagination-item-active:hover a {
  background-color: black;
  color: white;
  border: 1px solid black;
}

.candidateHotlistAntdPaginationMainDiv .ant-pagination-item-active:hover {
  border: none !important;
}

.candidateHotlistAntdPaginationMainDiv .ant-pagination-item {
  margin-inline-end: 0px;
  border: 1px solid lightgray;
  border-radius: 0px;
  background-color: white !important;
}

.candidateHotlistAntdPaginationMainDiv .ant-pagination-item-link {
  background-color: transparent !important;
  border-radius: 0px !important;
}
.candidate-hotlist-table-main-div .edit-but-in-hot-list {
  display: flex;
  gap: 15px;
  /* cursor: pointer; */
  align-items: center;
}
.candidate-hotlist-table-main-div .arrow-in-hot-list {
  /* rotate: 180deg; */
  margin-left: 10px;
  font-size: 17px;
  cursor: pointer;
}
.candidate-hotlist-table-main-div .ant-spin .ant-spin-dot {
  position: relative;

  left: 41rem;
  top: 11rem;
}

.candidate-hotlist-table-main-div .ant-spin .ant-spin-dot-item {
  background-color: #000000;
}
.candidate-hotlist-table-main-div .ant-pagination .ant-pagination-next {
  /* height: 30px !important; */
  border: 1px solid lightgray;
}

.candidate-hotlist-table-main-div .ant-pagination-item-link {
  background-color: white !important;
}

.candidate-hotlist-table-main-div .ant-pagination-prev {
  margin-inline-end: 0px !important;
  /* height: 30px !important; */
  border: 1px solid lightgray;
}

.export-modal .ant-modal-content {
  padding: 0px;
  border-radius: 8px;
}
.export-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  font-family: "Poppins";
  position: relative;
}
.export-container .close-container {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 20px;
  height: 20px;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.export-container .export-header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  background-color: #265f9d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.export-container .export-all {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}
.export-container .export-all > label {
  font-size: 18px;
  cursor: pointer;
}

.export-container .export-all > input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.export-container .or-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.export-container .or-container h2 {
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.export-container .or-container h2::after {
  content: "";
  display: block;
  width: 71px;
  height: 1.5px;
  background: #cdcdcdb8;
  right: 25px;
  top: 50%;
  position: absolute;
}
.export-container .or-container h2::before {
  content: "";
  display: block;
  width: 71px;
  height: 1.5px;
  background: #cdcdcdb8;
  left: 25px;
  top: 50%;
  position: absolute;
}

.export-container .custom-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.export-container .custom-container > input {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  box-sizing: border-box;
  height: calc(3em + 2px);
  margin: 0 0 1em;
  outline: none;
  padding: 1em;
  resize: none;
  width: 30%;
}

.export-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.export-btn > #export-submit-btn {
  background-color: #fff;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  color: #000;
  height: 40px;
  margin: 10px 0px 30px 0px;
  width: 150px;
}
.export-btn > #export-submit-btn:hover {
  background-color: #265f9d;
  color: #ffffff;
  transition: 0.5s ease-in-out;
}
.rocket-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lottie-text-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottie-text-box span {
  text-align: left;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
}


/* Laptop  */
@media screen and (max-width: 1024px) {
  .candidate-hotlist-damin-and-nameSearch .ant-select-selector {
    width: 16rem;
    padding-left: 10px;
  }
  .candidateHotlistAntdPaginationMainDiv .candidatesPerPageDiv {
    width: 30%;
  }
  .candidateHotlistAntdPaginationMainDiv .paginationMainDiv {
    width: 62%;
  }

  .candidate-hotlist-damin-and-nameSearch {
    width: 30% !important;
  }

  .candidate-hotlist-table-main-div .candidate-hotlist-table-in-button-section {
    gap: 6px;
  }
}

@media screen and (max-width: 1366px) {
  .candidate-hotlist-damin-and-nameSearch .ant-select-selector {
    /* width: 16rem; */
    padding-left: 10px;
  }

  .candidate-hotlist-table-main-div .table-container {
    /* height: 70vh; */
  }
}
