.manage-first-section .main-add-user {
  margin-right: 3.7%;
}
.manage-first-section .main-add-user .manage-addUser-btn {
  height: 45px;
  width: 170px;
  border-radius: 5px;
  opacity: 1;
  border: none;
  color: white;
  background-color: #00b282;
  /* margin-top: -5px; */
  font-size: 14px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
}

.modal-open-user.ant-modal .ant-modal-close-x {
  display: none;
}

.modal-open-user.ant-modal .ant-modal-close {
  display: none !important;
}

.modal-open-user .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgb(0 0 0 / 80%);
}

.modal-open-user.ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0;
}

.modal-open-user.ant-modal {
  top: 25% !important;
}

.pop-user-modal .user-header {
  height: 40px;
  width: 100%;
  background-color: #e8e8e8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.user-header .close-box {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.user-header .close-box .cross-btn {
  position: relative;
  bottom: 1.5px;
}

.pop-user-modal .user-header p {
  font-size: 15px;
  font-weight: 700;
}

.pop-user-modal .ass-user-details {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 35px;
}

.pop-user-modal .ass-user-details input {
  height: 40px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: 1px solid lightgray;
  padding-left: 20px;
}

.pop-user-modal .ass-user-details input::placeholder {
  font-size: 12px;
  color: #a4a5a5;
}

.pop-user-modal .user-add-click {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.pop-user-modal .user-add-click .user-btn {
  height: 40px;
  width: 110px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
  background-color: #00b282;
  font-size: 12px;
  margin-bottom: 25px;
}

.modal-select-manage.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 40px;
  padding: 0 15px;
}

.modal-select-manage.ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  position: relative;
  user-select: none;
  font-size: 14px;
}

.modal-select-manage.ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 4px;
  line-height: 30px;
  transition: all 0.3s, visibility 0s;
}

.modal-select-manage span[title="User Type"] {
  color: #a4a5a5;
}

.modal-select-manage.ant-select .ant-select-selection-item {
  display: flex;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ass-user-details > select:required:invalid {
  color: lightgray;
}

option[value=""][disabled] {
  display: none;
}

option {
  color: black;
  width: 100%;
  height: 20px;
  font-size: 18px;
  font-family: "Popins";
}

/* Akram */

span.formValidateError {
  width: 300px;
  color: red;
  text-align: left;
  font-size: 12px;
}
