.hotlistFormSection .ant-select-selection-item {
    font-weight: 500;
    font-size: 15px;
    font-family: "Mulish";
}

.main-form-sec .notForFlex {
    display: flex;
}

.addhotlist_popup_style {
    z-index: 999 !important;
}

.addhotlist_popup_style .ant-space {
    border: none !important;
}

.addhotlist_popup_style .ant-input {
    font-family: "Mulish" !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.addhotlist_popup_style .ant-select-item-option {
    font-weight: 500;
    font-family: "Mulish";
    font-size: 15px;
}

.addhotlist_popup_style .ant-space-align-center,
.ant-space-item {
    width: 100%;
    font-weight: 500;
}

.main-form-sec {
    display: flex;
    flex-direction: column;
    gap: 46px;

}

.main-form-sec .hotlist-website-Input-section {
    display: flex;
    gap: 13.8%;
}

.main-form-sec .hotlist-website-Input-section .hotlistFormSection input::placeholder {
    font: normal normal normal 15px/30px 'Poppins';
    color: #A4A5A5;

}

.main-form-sec .submit-btnSec {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-form-sec .submit-btnSec button {
    height: 61px;
    width: 13%;
    background: #00B282 0% 0% no-repeat padding-box;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #FFFFFF;
    font: normal normal 500 20px/38px 'Mulish';
    cursor: pointer;
}

.hotlist-website-Input-section .hotlistFormSection {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    /* align-items: center; */
}

.hotlistFormSection .ant-select-selection-placeholder {
    color: #a7abab;
    padding: 0 !important;
    font-size: 15px;
    font-weight: 500;
    font-family: "Mulish";

}

.hotlistFormSection .addForm-select .ant-select-selection-item {
    color: black !important;
    font-size: 15px;
    font-weight: 500;
    font-family: "Mulish";

}

.hotlistFormSection .ant-select-selector .ant-select-selection-item {
    color: black !important;
}

.hotlistFormSection .ant-select-selector {
    height: 48px !important;
    align-items: center;
    outline: none;
    border: none;
    box-shadow: 0 0 2px !important;
}

.hotlistFormSection .ant-select-selection-search-input {
    height: 48px !important;
    font-family: "Mulish" !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.hotlistFormSection .hotList_all_input {
    padding: 12px;
    height: 48px;
    width: 21.5vw;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: 0 0 2px;
    font-size: 15px;
    font-family: "Mulish";
    font-weight: 500;
}

.hotlistFormSection .location-state {
    box-shadow: 0 0 2px;
    padding: 12px;
    height: 48px;
    width: 18vw;
    border: none;
    outline: none;
    border-radius: 5px;
    display: flex;
    margin-right: 20px;
    font-size: 15px;
    font-weight: 500;
    font-family: "Mulish";
}

.submit-mainSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 55px;
}

.submit-mainSec .addMore-btn {
    margin-top: 25px;
    height: 55px;
    width: 11%;
    background: #00B282 0% 0% no-repeat padding-box;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #FFFFFF;
    font: normal normal 500 20px/38px 'Mulish';
    cursor: pointer;
}

/* Added By Akram */

.hotlist-website-Input-section span.ant-select-arrow.ant-select-arrow-loading {
    font-size: 1.3rem;
    color: red;
}