.addHotlist-mainSections {
  display: flex;
  width: 100%;
  height: 623px;
  justify-content: center;
  margin-bottom: 10%;
}

.addHotlist-mainSections .hotlist-form-and-head-handler {
  margin-top: 2.5%;
  width: 95%;
  height: 97%;
  display: flex;
  flex-direction: column;
  gap: 1.5%;
}

.hotlist-form-and-head-handler .add-hotlist-word {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.5%;
  background-color: #265f9d;
  border-radius: 10px 100px 0px 10px;
  color: white;
  border: none;
  outline: none;
  font-size: 19px;
  line-height: 63px;
  font-family: "Mulish";
  font-weight: 600;
}

.hotlist-form-and-head-handler .add-hotlist-form-handler {
  width: 100%;
  height: 88%;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 3%;
}

.dropDownArrow {
  position: absolute;
  top: 98%;
  left: 49%;
}

.hotlist-form-and-head-handler .new-guidelines {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hotlist-form-and-head-handler .new-guidelines .i-symbol {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #007AFF 0% 0% no-repeat padding-box;
  color: white;
  cursor: pointer;
  font-size: 17px;
}

.hotlist-form-and-head-handler .new-guidelines .guideLinesWord {
  text-align: left;
  font: italic normal normal 15px/25px Poppins;
  letter-spacing: 0px;
  color: #007AFF;
  cursor: pointer;
}

.addHotlist-guideLine-modal .ant-modal-close {
  top: -20px !important;
  left: 100% !important;
  background: white !important;
  border-radius: 50%;
}

.addHotlist-guideLine-modal .ant-modal-close-x {
  font-size: 13px !important;
}

/* guideLinesSettingsOut settings */

.guideLinesSettingsOut {
  padding: 2rem;
  ;
}

.guideLinesSettingsOut h1 {
  text-align: center;
}

.CustomReactQuill .ql-toolbar.ql-snow {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

@media only screen and (max-width: 1366px) {
  .dropDownArrow {
    top: 113%
  }
}