.note-one-two-three-sec .add-more-note {
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-right: 40px;
  align-items: center;
}

.note-one-two-three-sec .add-more-note span {
  height: 23px;
  width: 23px;
  border: 1px solid lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background-color: #0ab587;
  color: white;
  padding-bottom: 5px;
}

.note-one-two-three-sec .add-more-note p {
  font-size: 15px;
  font-weight: 700;
}

.more-note-modal-open.ant-modal .ant-modal-close {
  display: none;
}

.more-note-modal-open.ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0;
}

.more-note-modal-open.ant-modal {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  pointer-events: none;
  position: relative;
  top: 22% !important;
  width: auto;
  max-width: calc(100vw - 32px);
  padding-bottom: 24px;
}

.add-note-modal-details .more-note-header {
  height: 50px;
  width: 100%;
  color: white;
  background-color: #265f9d;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-note-modal-details .more-note-header p {
  font-size: 18px;
  font-weight: 500;
}

.add-note-modal-details .second-note-div-sec .all-note-data {
  /* overflow-y: scroll; */
  height: 320px;
  padding: 25px 0 0 25px;
}

.add-note-modal-details .second-note-div-sec .all-note-data textarea {
  height: 295px;
  width: 100%;
  outline: none;
  border: none;
  resize: none;
}

.add-note-modal-details .second-note-div-sec .all-note-data textarea::-webkit-scrollbar {
  width: 15px;
}

.add-note-modal-details .second-note-div-sec .all-note-data textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 50px grey;
  border-radius: 0px;
}

.add-note-modal-details .second-note-div-sec .all-note-data textarea::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 10px;
  width: 0px;
  border: 2px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.add-note-modal-details .second-note-div-sec .more-note-under-line {
  width: 100%;
  border-bottom: 2px solid lightgray;
}

.add-note-modal-details .add-note-sec-last-click {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20px;
}

.add-note-modal-details .add-note-sec-last-click button {
  height: 45px;
  width: 120px;
  border: 1px solid lightgray;
  border: none;
  outline: none;
  border-radius: 5px;
  color: white;
  background-color: #00b282;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

.add-note-modal-details .more-note-close-item {
  height: 20px;
  width: 20px;
  border: 1px solid lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  position: relative;
  left: 56.5rem;
  bottom: 28.5rem;
  cursor: pointer;
}

.add-note-modal-details .more-note-close-item p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  /* margin-left: 1px; */
}


.ant-btn-default:not(:disabled):hover{
  color: white !important;
  border-color: transparent !important;
}