.box-of-checkbox{
    width: 200px;
    height: 250px;
    background-color: #e1dfdf;
    top: 42px;
    left: -98px;
    position: absolute;
    border-radius: 6px;
    padding: 8px;
    z-index: 10;
}

.above-arrow::after{
    content: " ";
    position: absolute;
    right: -1px;
    top: 20px;
    border-top: none;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 22px solid #d7d8db;
}


.pop-search-div input {
    width: 100%;
    height: 26px;
    border: none;
    padding: 0px 23px 0px 23px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 5px;
    accent-color: green;
    text-align: left;
    font: normal normal normal 13px/16px Mulish;
    outline: none;
    padding-left: 27px;
  }
  
.pop-search-div input::placeholder {
    text-align: left;
    font: italic normal normal 11px/14px Mulish;
    letter-spacing: 0px;
    color: #949494;
}

.pop-search-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-of-filter{
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .footer-of-filter .submit-btn-for-filter{
    width: 60px;
    margin: 0px;
    background: 0% 0% no-repeat padding-box padding-box #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 25px;
    text-shadow: 0px 2px 2px #00000029;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 3px;
    color: #ffffff;
    border: none;
    font-size: 13px;
    font-family: "Poppins";
    line-height: 13px;
    font-weight: medium;
  }

  .footer-of-filter .clear-btn-for-filter{
    width: 60px;
    margin: 0px;
    background: transparent linear-gradient(180deg, #bebebe 0%, #949494 100%) 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 25px;
    text-shadow: 0px 2px 2px #00000029;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: 3px;
    color: #ffffff;
    border: none;
    font-size: 13px;
    font-family: "Poppins";
    line-height: 13px;
    font-weight: medium;
  }

  .selection-div-cover .selection-div{
    display: flex;
    margin-top: 10px;
    padding: 5px;
  }

  .check-wrap{
    display: flex;
    align-items: center;
  }

  #slection-ul{
    margin: 0;
    padding: 0;
    height: 135px;
    overflow-y: scroll;
  }

  #slection-ul::-webkit-scrollbar{
    display: none;
  }

  #slection-ul li{
    list-style: none;
    display: flex;
    justify-content: start;
    line-height: 15px;
    padding: 5px;
    margin-bottom: 2px;
    white-space: break-spaces;
    align-items: center;
  }


  .range-picker-filter .ant-picker-suffix, .range-picker-filter .ant-picker-clear{
    display: none;
  }