.main-table-div{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    
}
.main-table-div table{
    width: 100%;
    text-align: start;
    border-collapse: collapse;
    
}
.main-table-div table>thead>tr>.title-th{
    height: 3rem;
    background: #f2f2f2;
    text-align: center;
    width: 50%;
    position: sticky;
    top: 0;
}

.main-table-div table>tbody>tr>td{
    border-bottom: 1px solid #cfcfcf;
    border-top: 1px solid #cfcfcf;
    text-align: center;
    height: 2.5rem;
    background: #fff;
    width: 50%;
}



