* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.bench-sales-modal .ant-modal-content {
    padding: 0px 85px 50px 85px !important;
    height: 550px !important;
    background-color: #F5F5F5 !important;
}
.bench-sales-modal {
    width: 935px !important;
    transform-origin: -371.5px 321px !important;
    margin-top: 15px !important;
}

.bench-sales-modal .popup-default-close-button {
    display: block;
    border: none;
    font-size: 22px;
    font-style: normal;
    line-height: 15px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    position: absolute;
    top: -23px;
    right: -30px;
    color: black;
    background: white;
    border-radius: 13px;
    cursor: pointer;
}

.bench-sales-modal .bench-sales-popup-edit-button {
    position: absolute;
    top: 58px;
    right: 75px;
    display: block;
    border: none;
    font-size: 24px;
    font-style: normal;
    line-height: 15px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    color: black;
    background: white;
    border-radius: 13px;
    cursor: pointer;
    opacity: .3;
}

.bench-sales-modal .ant-modal-close {
    background: none !important;
    display: none;
}

.bench-sales-modal .bench-sales-modal-header-div .bench-sales-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D4D4D4;
    font: normal normal bolder 18px/31px Mulish;
    width: 380px;
    height: 65px;
    border-radius: 0px 0px 100px 100px;
    letter-spacing: .2px;
}

.bench-sales-modal .bench-sales-modal-header-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
    padding-bottom: 15px;
}

.bench-sales-modal-table {
    width: 735px;
    height: 425px;
    border: 1px solid #707070;
    border-radius: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    font: normal normal normal 15px/22px Poppins;
    margin-left: 15px;
}

.bench-sales-modal-table-tbody {
    border-bottom: 1px solid #707070;
    padding: 15px 0px 15px 0px;
    width: 100%;
    display: flex;
}

.bench-sales-modal-table-body {
    padding: 11px 0px 11px 0px;
    display: flex;
    width: 100%;
}

.bench-sales-modal .ant-spin .ant-spin-dot {
    position: relative;
    left: 22rem;
    top: 11rem;
}

.bench-sales-modal .ant-spin .ant-spin-dot-item {
    background-color: #000000;
}


