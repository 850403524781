* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-manage-user {
  width: 100%;
  padding: 30px;
  background-color: #d3d3d359;
  height: calc(100vh - 65px);
}

.main-manage-user .manage-first-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-manage-user .manage-first-section .raising-star {
  display: flex;
  margin-left: 45px;
  gap: 10px;
}

.manage-first-section .raising-star .user-permission {
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.manage-first-section .raising-star .user-permission .addOrRemoveUsers {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.main-manage-user .border-line {
  border-bottom: 1px solid lightgray;
  margin-top: 1.5%;
  width: 93.5%;
  margin-left: 5.5%;
}

.main-manage-user .second-section {
  margin-left: 5.5%;
  margin-top: 15px;
}

.main-manage-user .second-section .adminCanChangePermissions {
  font-size: 15px;
  font-weight: 500;
  margin-top: 3px;
}

.main-manage-user .third-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: calc(100vh - 245px);
}

.third-section .border-radius-for-ManageUser {
  border-radius: 5px;
  border: 1px solid lightgray;
  width: 90%;
  margin-left: 8%;
  height: 96%;
  overflow-y: scroll;
}

.third-section .border-radius-for-ManageUser::-webkit-scrollbar {
  display: none;
}

.main-manage-user .third-section .ManageAddUserTable {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
  position: relative;
  height: 100%;
  overflow-y: scroll;
}
.spinner {
  position: absolute;
  top: 250px;
  left: 36rem;
}
.spinner .ant-space-item .ant-spin .ant-spin-dot .ant-spin-dot-item {
  background-color: black;
}
.main-manage-user .third-section .ManageAddUserTable thead {
  height: 40px !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: lightgray;
  position: sticky;
  top: 0;
  z-index: 10;
}

.main-manage-user .third-section .ManageAddUserTable thead tr th {
  text-align: start;
  padding: 10px;
  font-size: 15px;
}

.main-manage-user .third-section .ManageAddUserTable tbody td {
  padding: 10px;
  border-bottom: 1.5px solid #bfc0c0;
  height: 45px;
}

.main-manage-user
  .third-section
  .ManageAddUserTable
  tbody
  tr
  td
  .manage-two-clicker {
  display: flex;
  gap: 30px;
  text-align: center;
  align-items: center;
}

.manage-user-switch .ant-switch.ant-switch-checked{
  background-color: #00b282;
}

.main-manage-user .data-not-found {
  display: flex;
  position: absolute;
  left: 28rem;
  top: 13rem;
  font-size: 30px;
  align-items: center;
  text-align: center;
  flex-direction: column;
  font-weight: 600;
}
.main-manage-user .data-not-found-icon {
  font-size: 45px;
}
