.add-Benchsales-popUp {
    top: 145px !important;
    max-width: calc(100vw - 67px) !important;
}

.add-Benchsales-popUp .ant-modal-close{
    right: -25px;
    top: -25px;
    color: black;
    background: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px !important;
    height: 25px !important;
}

.add-Benchsales-popUp .ant-modal-close:hover{
    color: black !important;
    background-color: #FFFFFF !important;
}


.add-Benchsales-popUp .ant-modal-content {
    padding: 45px 24px !important;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    height: 500px !important;
}

.bench-main-sec .salesDetails-para {
   font-size: 17px;
   font-weight: 700;
   margin-left: 38px;
}

.benchSales-Input-section {
    display: flex;
    justify-content: start;
    gap: 10%;
    margin-left: 3%;
    height: 100px;
}

.benchSales-Input-section .benchSalesFormSection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


.benchSales-Input-section .benchSalesFormSection .benchSales_all_input {
    padding: 12px;
    height: 48px;
    width: 21.5vw;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: 0 0 2px;
}

.bench-main-sec .add-btnSec {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bench-main-sec .add-btnSec button {
    margin-top: 25px;
    height: 65px;
    width: 14%;
    background: #00B282 0% 0% no-repeat padding-box;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #FFFFFF;
    font: normal normal 500 20px/38px 'Mulish';
    cursor: pointer;
}

.backTo-search{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.backTo-search .go-backSec{
    margin-top:110px;
    height: 55px;
    width: 11%;
    border-radius: 5px;
    background: none;
    outline: none;
    border: none;
    color: black;
    font: normal normal 800 15px/38px 'Mulish';
    cursor: pointer;
}

.benchSalesFormSection
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

span.formValidateError {
    width: 300px;
    color: red;
    text-align: left;
    font-size: 12px;
  }