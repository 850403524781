

.note-all-data-main-div {
  height: 300px !important;
  overflow-y: scroll;
}

.main-note-btn .note-click {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 7px;
  text-align: start;
  cursor: pointer;
}

.note-modal-open-close.ant-modal .ant-modal-close-x {
  display: none;
}

.note-modal-open-close.ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0;
}

.note-modal-open-close.ant-modal {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  pointer-events: none;
  position: relative;
  top: 0% !important;
  width: auto;
  max-width: calc(100vw - 32px);
  padding-bottom: 24px;
}

.note-one-two-three-sec {
  height: max-content !important;
  padding-bottom: 13px;
}

.note-one-two-three-sec .note-header {
  height: 50px;
  width: 100%;
  color: white;
  background-color: #265f9d;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note-one-two-three-sec .note-header p {
  font-size: 18px;
  font-weight: 500;
}

.note-one-two-three-sec .note-create-date-by {
  height: 40px;
  width: 100%;
  color: black;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
}

.note-one-two-three-sec .note-create-date-by p {
  font-size: 14px;
  font-weight: 600;
  margin-left: 25px;
}

.note-one-two-three-sec .note-second-details {
  display: flex;
  justify-content: space-between;
}

.note-one-two-three-sec .note-second-details p {
  font-size: 13px;
  font-weight: 600;
}

.note-one-two-three-sec .note-all-data {
  height: max-content;
}

.note-one-two-three-sec .note-all-data-main-div::-webkit-scrollbar {
  width: 15px;
}

.note-one-two-three-sec .note-all-data-main-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 50px grey;
  border-radius: 0px;
}

.note-one-two-three-sec .note-all-data-main-div::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  width: 0px;
  border: 2px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.note-one-two-three-sec .note-all-data .note-details-under-line {
  width: 100%;
  border-bottom: 1px solid lightgray;
  margin-top: 20px;
}

.note-one-two-three-sec .note-close-item {
  height: 20px;
  width: 20px;
  border: 1px solid lightgray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  position: relative;
  left: 56.5rem;
  bottom: 25.5rem;
  cursor: pointer;
}

.note-one-two-three-sec .note-close-item p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.noteLoader{
  position: relative;
  left: 47%;
  top: 37%;
}
.noteLoader .ant-spin-dot-item{
    background-color: black !important;
}

.add-last-note {
  width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

/* @media screen and (max-width: 1366px) {

  .note-modal-open-close.ant-modal{
    top: 0% !important;
  }

} */