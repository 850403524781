.column-add-data-modal .column-click-item {
  height: 33px;
  width: 33px;
  margin-top: 10px;
  cursor: pointer;
}

.column-add-data-modal .column-click-item:hover > path{
  fill: rgb(38, 95, 157);
}

.main-column-modal.ant-modal .ant-modal-close-x {
  font-size: 17px;
}

.main-column-modal.ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px;
}

.main-column-modal.ant-modal {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  pointer-events: none;
  position: relative;
  top: 140px;
  width: auto;
  max-width: calc(100vw - 32px);
  padding-bottom: 24px;
}

.column-modal-all-details .column-first-item-setting {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 15px;
  padding: 15px 0px 15px 30px;
}

.column-modal-all-details .column-first-item-setting .column-set {
  font-size: 15px;
  font-weight: 600;
  font-family: sans-serif;
}

.column-modal-all-details .column-second-details-section {
  padding: 15px 0px 10px 30px;
  height: 450px;
  overflow-y: scroll;
}

.column-modal-all-details .column-second-details-section .candidate-details {
  /* height: 30px;
    width: 200px;
    border: 1px solid black; */
  display: flex;
  width: max-content;
  gap: 7px;
  text-align: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}



.column-modal-all-details .column-second-details-section .candidate-details .click-check {
  height: 17px;
  width: 17px;
  accent-color: green;
  outline: none;
  cursor: pointer;
}

.column-modal-all-details .column-second-details-section .candidate-details .person-details {
  font-size: 14px;
  font-weight: 500;
  font-family: sans-serif;
  cursor: pointer;
}

.column-modal-all-details .last-two-click-btn {
  display: flex;
  gap: 5px;
  justify-content: end;
  align-items: center;
  height: 15%;
  width: 95%;
  padding: 10px 0px;
}

.column-modal-all-details .last-two-click-btn .cancel-click-btn {
  height: 35px;
  width: 90px;
  border-radius: 5px;
  background-color: #D4D4D4;
  color: black;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  font-family: sans-serif;
  cursor: pointer;
}

.column-modal-all-details .last-two-click-btn .apply-click-btn {
  height: 35px;
  width: 90px;
  border-radius: 5px;
  background-color: #00B282;
  color: white;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  font-family: sans-serif;
  cursor: pointer;
}

.columnSettingImgModal {
  width: 26px;
  height: 26px;
  position: relative;
  top: 2px;
}

.columnSettingImgModal > path{
  fill: black;
}