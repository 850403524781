.drop-arrow {
  position: absolute;
  top: 98%;
  left: 49%;
}

.scroll-down {
  position: absolute;
  bottom: 10px;
  display: block;
  text-align: center;
  font-size: 20px;
  text-decoration: none;
  width: 22px;
  height: 22px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  z-index: 9;
  left: 50%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 4s ease-in-out infinite;
  -moz-animation: fade_move_down 4s ease-in-out infinite;
  animation: fade_move_down 4s ease-in-out infinite;
}
.scroll-down-two {
  position: absolute;
  bottom: 15px;
  display: block;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
  width: 22px;
  height: 22px;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  z-index: 9;
  left: 50%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 3s ease-in-out infinite;
  -moz-animation: fade_move_down 3s ease-in-out infinite;
  animation: fade_move_down 3s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
  0% {
    -moz-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}


@media screen and (max-width: 1377px) {

  .drop-arrow {
    position: absolute;
    top: 115%;
    left: 49%;
  }

}

@media screen and (max-width: 1024px) {

  .drop-arrow {
    position: absolute;
    top: 98%;
    left: 49%;
  }

}