

.manage-edit-user-pop.ant-modal .ant-modal-close-x {
  display: none;
}

.manage-edit-user-pop.ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0;
}

.edit-user-modal .edit-user-header {
  height: 40px;
  width: 100%;
  background-color: #e8e8e8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-open-user.ant-modal{
  top: 25% !important;
}

.edit-user-modal .edit-user-header p {
  font-size: 15px;
  font-weight: 700;
}

.edit-user-modal .edit-first-details {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 35px;
}

.edit-user-modal .edit-first-details input {
  height: 40px;
  width: 300px;
  border-radius: 8px;
  outline: none;
  border: 1px solid lightgray;
  padding-left: 20px;
}

.edit-user-modal .edit-first-details input::placeholder {
  font-size: 12px;
  color: lightgray;
}

.edit-select-manage-modal.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 40px;
  padding: 0px 0px;
}

.edit-select-manage-modal.ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.edit-select-manage-modal.ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 196px;
  color: lightgray;
  font-size: 13px;
}

.edit-select-manage-modal.ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  position: relative;
  user-select: none;
  top: 4px;
  left: 0px;
}

.edit-select-manage-modal.ant-select .ant-select-selection-item {
  /* flex: 1; */
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.edit-user-modal .manage-edit-user-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.edit-user-modal .manage-edit-user-btn button {
  height: 40px;
  width: 110px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: white;
  background-color: #00b282;
  font-size: 12px;
}
