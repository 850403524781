.login-container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(../../src/assets/bg12.png); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  background: #EDF1F4 0% 0% no-repeat padding-box;
  position: relative;
}
.login-container .login-box {
  height: 100vh;
  width: 481px;
  position: fixed;
  left: -238px;
  top: -8px;
  background-image: url(../../src/assets/Logo\ Grey.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.login-container >img {
  position: absolute;
  height: 100%;
  left: -15%;
}
.login-container .login-form-container {
  width: 485px;
  height: 75%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  background: #265f9d 0% 0% no-repeat padding-box;
  box-shadow: 24px 21px 99px #00000024;
  border-radius: 10px;
}

.login-form-container > .form-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 10px;
  margin-top: 10px;
}
.login-form-container > h1 {
  margin-top: 0 !important;
  text-align: left;
  font-size: 28px;
  font-weight: 200;
  font-family: "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
}
.login-form-container  .form-box .input-box {
  display: flex;
  flex-direction: column;
  height: max-content;
}
.login-form-container > .form-box > .input-box .input-field {
  width: 320px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #265f9d;
  border-radius: 8px;
  padding-left: 30px;
  text-align: left;
  font: normal normal 400 16px/18px Poppins;
  letter-spacing: 0px;
}

.login-form-container > .form-box  .input-box .input-field::placeholder {
  text-align: left;
  font: normal normal 300 16px/18px Poppins;
  letter-spacing: 0px;
  color: #c5c5c5;
}
.login-form-container > .form-box > .input-box .input-field:focus-within {
  outline: none;
  border: none;
}

.login-form-container  .form-box > .input-box .input-field:focus,
.login-form-container  .form-box > .input-box .input-field:hover {
  border: 1px solid #00b282;
  box-shadow: #4caf50 0px 1px 3px 0px, #4caf50 0px 0px 0px 1px;
}

.login-form-container  .form-box .input-box .input-errors {
  /* width: 320px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding-left: 30px;
  text-align: left;
  font: normal normal 400 16px/18px Poppins;
  letter-spacing: 0px; */
  border: 1px solid #db1e05;

}

.login-form-container .form-box .login-form-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}
.login-form-container .form-box .login-form-box > .login-btn {
  height: 50px;
  width: 220px;
  border: 1.5px solid #ffffff;
  border-radius: 8px;
  background: transparent;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}
.login-form-container .form-box .login-form-box > .login-btn:hover {
  background: #00b282 0% 0% no-repeat padding-box;
  transition: all 0.5s ease;
  border: 1.5px solid #00b282;
}
