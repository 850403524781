.main-div {
    width: 100%;
height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.main-div .div-one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    height: 50px;
    padding: 20px;
    background: #e6e6e6 0% 0% no-repeat padding-box;
    border-top: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    border-left: 1px solid #cfcfcf;

}

.main-div .div-two {
    width: 70%;
    height: 68vh;
    border: 1px solid #cfcfcf;
    background-color: #fff;
}

.search-icon-in-user-activity {
    position: relative;
    left: 1.8rem;
    top: 0.2rem;
}

.switch-btn-in-user-activity .ant-switch.ant-switch-checked{
    background: #00b282;
}
.switch-btn-in-user-activity .ant-switch.ant-switch-checked:hover{
    background: #00b282;
}
.main-div .div-three {
    display: flex;
    justify-content: space-between;
    border-radius: 0px 0px 10px 10px;
    align-items: center;
    background: #e6e6e6 0% 0% no-repeat padding-box;
    height: 50px !important;
    padding-left: 35px;
    padding-right: 35px;
    width: 70%;
    border-bottom: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    border-left: 1px solid #cfcfcf;
}

.search-user-activity-inp {
    width: 290px;
    border-radius: 15px;
    height: 32px;
    outline: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    border: 1px solid #d5e9ff;
    opacity: 1;
    padding-left: 30px;
}

.switch-n-date {
    display: flex;
    align-items: center;
    gap: 10px;
}


/* Pagination CSS  */


.div-three .ant-pagination-item {
    margin-inline-end: 0px;
    border: 1px solid lightgray;
    border-radius: 0px;
    background-color: white !important;
}

.div-three .ant-pagination .ant-pagination-item-active a {
    background-color: black !important;
    color: white !important;
}

.div-three .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
    font-size: 12px;
    outline: none;
    border-radius: 0px;

}

.div-three .ant-pagination .ant-pagination-disabled .ant-pagination-item-link {
    background-color: white !important;
    border: 1px solid transparent;
    font-size: 12px;
    outline: none;
    border-radius: 0px;
}

.div-three .ant-pagination .ant-pagination-prev {
    margin-inline-end: 0;
    font-size: 12px;
    outline: none;
    border: 1px solid #cfcfcf;
    background-color: white !important;
    border-radius: 0px;
}

.div-three .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    font-size: 12px;
    outline: none;
    border: 1px solid #cfcfcf;
    background-color: white !important;
    border-radius: 0px;
}

.user-activity-pagination {
    display: flex;
    justify-content: flex-end;
    width: 32%;
}

.nex-prev-disable-in-user-activity {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid lightgray;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
}


.per-page-user-activity .ant-select-selector {
    font-size: 14px;
    font-weight: 600;
    width: 23.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    background-color: #a4a5a5 !important;
    color: #ffffff !important;
    border-radius: 4px !important;
}

.per-page-user-activity .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-inline-start: 15px;
}

.per-page-user-activity .ant-select-arrow {
    display: none;
}

.per-page-user-text {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;

}


@media screen and (max-width: 1024px) {
    .user-activity-pagination{
        width: 45%;
    }
    .main-div .div-one{
        width: 90%;
    }
    .main-div .div-two{
        width: 90%;
        
    }
    .main-div .div-three{
        width: 90%;
        padding-right: 8px;

    }
    .per-page-user-activity{
        width: 24%;

    }
    /* .okkkkk{
        width: 31%;
    } */
}
