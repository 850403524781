.delete-popup-modal p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
  font-weight: 700;
  /* margin-bottom: 50px; */
  position: relative;
  top: -1rem;
  font-size: 18px;
  margin-bottom: 20px;
}

.delete-popup-modal .ant-modal-content {
  width: 320px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  left: 5rem;
  top: 10rem;
}

.delete-popup-modal .ant-modal-close{
  display: none;
}

.delete-popup-modal .ant-modal-root .ant-modal-mask {
  background-color: rgb(0 0 0 / 78%);
}

.delete-popup-modal .delete-no-but {
  width: 100px;
  height: 40px;
  background-color: #d4d4d4;
  color: #ffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}

.delete-popup-modal .delete-popup-but-div {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.delete-popup-modal .delete-yes-but {
  width: 100px;
  height: 40px;
  background-color: #00b282;
  color: #ffff;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;

}

.delete-popup-modal .anticon svg {
  display: none !important;
}
