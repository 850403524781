* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header-main-section {
    position: sticky;
    top: 0;
    background: #265F9D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 65px;
    z-index: 1000;
}

.header-main-section .header-logo-section {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 50px;
    gap: 15px;
    cursor: pointer;
}


.header-main-section .header-profile-photo-section {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-right: 30px;
    gap: 5px;
    width: 8%;
}

.header-main-section .header-profile-photo-section .header-profile-photo {
    border: 2px solid white;
    border-radius: 50px;
    height: 40px;
    cursor: pointer;
}

/* DropDown CSS */

.ProfileDropDown-main-section {
    position: fixed;
    top: 70px;
    right: 3%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 15px #00000029;
    border: 1px solid #E8E8E8;
    border-radius: 10px 0px 10px 10px;
    opacity: 1;
    width: 320px;
    height: 255px;
    z-index: 999;
    padding-left: 15px;
    height: max-content;
    max-height: 270px;
}

.ProfileDropDown-main-section .ProfileDropDown-first-section {
    display: flex;
    justify-content: start;
    padding: 20px 0px 0px 30px;
    height: 100px;
    border-bottom: 2px solid #E8E8E8;
    gap: 15px;
}

.ProfileDropDown-main-section .ProfileDropDown-profile-photo {
    border-radius: 50px;
    height: 60px;
}

.ProfileDropDown-main-section .ProfileDropDown-user-section {
    border-bottom: 2px solid #E8E8E8;
    display: flex;
    flex-direction: column;
    height: 120px;
    padding: 20px 0px 0px 30px;
    gap: 8px;
}

.ProfileDropDown-main-section .ProfileDropDown-user-section .ProfileDropDown-manage-user {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.ProfileDropDown-main-section .ProfileDropDown-LogOut-btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    gap: 5px;
    padding: 11px; 
}

.ProfileDropDown-main-section .ProfileDropDown-LogOut-btn-section .ProfileDropDown-LogOut-btn {
    border: none;
    background: none;
    font: normal normal 600 18px/30px Poppins;
    cursor: pointer;
}

.ProfileDropDown-main-section .ProfileDropDown-LogOut-btn-section .ProfileDropDown-LogOut-btn-icon {
    font: normal normal bolder 23px/30px Poppins;
    cursor: pointer;
}
.ProfileDropDown-user-and-login-activity{
    display: flex;
    gap: 8px;
}

/* Laptop Design  */

@media screen and (max-width: 1280px) {
    .header-main-section {
        width: 100%;
        height: 65px;
        z-index: 1000;
    }

    .ProfileDropDown-main-section {
        position: fixed;
        top: 70px;
    }

}